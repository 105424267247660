<template>
 

  <v-app>
    <v-navigation-drawer
                    absolute
                    left
                    app
                    
                    class="px-3 py-3"
                    v-model="drawer"
                    :permanent="$vuetify.breakpoint.mdAndUp"
                >
    
                <v-list>
        

        <v-list-item link @click="$router.push('/')">
          <v-list-item-content>
            <v-img
  lazy-src="@/assets/vb-logo.png"
  max-width="150"
  
  src="@/assets/vb-logo.png"
></v-img>
          </v-list-item-content>

      
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="selectedItem"
          color="primary"
        >
          <v-list-item @click="$router.push('/')">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Home</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="$router.push('/saisonstueck')">
            <v-list-item-icon>
              <v-icon>mdi-drama-masks</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Saisonstück</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="$router.push('/wintermaerchen')">
            <v-list-item-icon>
              <v-icon>mdi-pine-tree</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Wintermärchen</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!--<v-list-item @click="$router.push('/wintermaerchen')">
            <v-list-item-icon>
              <v-icon>mdi-file-sign</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Mitglied werden</v-list-item-title>
            </v-list-item-content>
          </v-list-item>-->

        </v-list-item-group>
      </v-list>

                    
    </v-navigation-drawer>
      
       <v-toolbar right style="max-height: 65px" class="hidden-md-and-up">
        
        <v-app-bar-nav-icon
                            class="hidden-md-and-up"
                            @click.stop="drawer = !drawer"
                        ></v-app-bar-nav-icon>

                        <v-img @click="$router.push('/')"
  lazy-src="@/assets/vb-logo.png"
  max-width="80"
  
  src="@/assets/vb-logo.png"
></v-img>
                    </v-toolbar>
                    



  <!-- Sizes your content based upon application components -->
  <v-main>

    <!-- Provides the application the proper gutter -->
    <v-container
    style="padding-top: 4rem; padding-bottom: 7rem;">

      <!-- If using vue-router -->
      <h1>kleines Theater der Volksbühne Worms</h1>
      <br /><br />
      <v-slide-x-transition mode="out-in">
      <router-view />
    </v-slide-x-transition>
    </v-container>
  </v-main>

  <v-footer app padless bottom absolute>
    <v-col
      class="text-center"
      cols="12"
    >
      {{ new Date().getFullYear() }} — <strong>Volksbühne Worms 1908 e. V.</strong> | <span @click="$router.push('/impressum')">Impressum</span> - <span @click="$router.push('/datenschutzerklaerung')">Datenschutzerklärung</span>
      
    </v-col>
  </v-footer>
</v-app>


</template>

<style lang="scss">
#app {
  font-family: Verdana, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  scroll-behavior: smooth;
  a{
    color: #971b1b;
    text-decoration: none;
  }
}

html {
  scroll-behavior: smooth;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #000;

    &.router-link-exact-active {
      color: #971b1b;
    }
  }
}
</style>

<script>
export default {
  data () {
    
    return {
      drawer: null,   
    }
    
  }
}

</script>