import Vue from 'vue'
import VueRouter from 'vue-router'
//import HomeView from '../views/HomeView.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue')
  },
  {
    path: '/saisonstueck',
    name: 'saisonstueck',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "saisonstueck" */ '../views/SaisonView.vue')
  },
  {
    path: '/wintermaerchen',
    name: 'wintermaerchen',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "wintermaerchen" */ '../views/WinterView.vue')
  },
  {
    path: '/impressum',
    name: 'impressum',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "impressum" */ '../views/ImpressumView.vue')
  },
  {
    path: '/datenschutzerklaerung',
    name: 'datenschutzerklaerung',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "datenschutzerklaerung" */ '../views/DatenschutzView.vue')
  }
]

const router = new VueRouter({
  routes,
  mode: 'history',
})

export default router
