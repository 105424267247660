import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({

    theme: {
        dark: false,
        themes: {
            
          light: {
            primary: '#971b1b',
            secondary: '#b0bec5',
            anchor: '#8c9eff',
          },
        },
      },

});
